








import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'TaintIndex' })
export default class Index extends VueBase {}
